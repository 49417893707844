import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { formatProductDescription } from '@/components/home/Authority/utils';
import { SELECT_PRODUCT_ITEM } from '@/graphql/disclosure/mutations';
import { GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER } from '@/graphql/disclosure/queries';
import {
  GetActiveDisclosureCycleQuery,
  ProductModel,
  SelectProductMutation,
  SelectProductMutationVariables,
} from '@/lib/discloser/__generated__/graphql';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/system';

type Props = { data: ProductModel; setIsFreeProductSelected: (value: boolean) => void };

const StyledBox = styled(Box)(({ theme }) => ({
  '& ul li::before': {
    color: '#1976d2',
    marginRight: 2,
  },
  '& h4': {
    marginBottom: 4,
  },
}));

export const ProductCard = ({ data, setIsFreeProductSelected }: Props) => {
  const { data: disclosureCycleData, loading: getActiveDisclosureCycleLoading } =
    useQuery<GetActiveDisclosureCycleQuery>(GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER, {
      client: disclosureClient,
      onError: onApolloError,
    });

  const { t } = useTranslation('common', { keyPrefix: 'common.productCard' });
  const router = useRouter();

  const { name, benefits, summary, isoCurrencyCode, amount, productListItemId } = formatProductDescription(data);

  const [
    selectProduct,
    { loading },
  ] = useMutation<SelectProductMutation, SelectProductMutationVariables>(SELECT_PRODUCT_ITEM, {
    client: disclosureClient,
    onError: onApolloError,
    variables: {
      productListItemId,
      disclosureCycleId: disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId as string,
    },
  });

  const selectProductItem = async () => {
    if (amount === 0) {
      setIsFreeProductSelected(true);
      await selectProduct({
        variables: {
          productListItemId,
          disclosureCycleId: disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId as string,
        },
      });
    } else {
      await selectProduct({
        variables: {
          productListItemId,
          disclosureCycleId: disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId as string,
        },
      });

      await router.push({
        pathname: Routes.Payment,
      });
    }
  };

  return (
    <Box
      border="2px solid"
      borderRadius="4px"
      padding="14px"
      bgcolor="background.default"
      sx={{
        border: '1px solid grey',
        transition: theme => theme.palette.background.default,
        '&:hover': {
          borderColor: theme => theme.palette.primary.main,
        },
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack sx={{ flexGrow: 1 }}>
        <Typography
          variant="h5"
          component="h1"
          mb={2}
        >
          {name}
        </Typography>

        <StyledBox
          data-testid="product-summary"
          mb={2}
          dangerouslySetInnerHTML={{ __html: summary }}
        />

        <Box>
          <Typography
            variant="h4"
            display="inline"
          >
            {new Intl.NumberFormat('en-US', { style: 'currency', currency: isoCurrencyCode }).format(amount)}
          </Typography>
          <Typography
            variant="body2"
            display="inline"
          >
            {t('excVat')}
          </Typography>
        </Box>
        <Button
          disabled={getActiveDisclosureCycleLoading || loading}
          variant="outlined"
          sx={{
            marginTop: 1,
            marginBottom: 2,
            borderColor: '#4B6A82',
            color: '#4B6A82',

            '&:hover': {
              borderColor: '#4B6A82',
              color: '#FFFFFF',
              background: alpha('#4B6A82', 0.8),
            },
          }}
          onClick={selectProductItem}
          fullWidth
        >
          {t('selectPlan')}
        </Button>
        <StyledBox
          data-testid="product-benefits"
          className="product-card-description"
          mb={2}
          dangerouslySetInnerHTML={{ __html: benefits }}
          sx={{
            '& ul': {
              content: '"\\2022"', // Unicode for bullet point
              padding: 0,
              margin: 0,
              listStyleType: 'none',
            },
            '& li': {
              display: 'flex',
            },
            '& ul li::before': {
              content:
                'url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%3Cpath%20d%3D%22M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z%22%20fill%3D%22%234caf50%22/%3E%3C/svg%3E)',
            },
          }}
        />
      </Stack>
    </Box>
  );
};
