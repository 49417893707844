import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Routes } from '@/utils/urls';
import { Box, Dialog, DialogContent, IconButton, Typography } from '@mui/material';

type DialogProps = {
  open: boolean;
  handleClose: () => void;
};

export default function EditQuestionnaireSetup({ open, handleClose }: DialogProps) {
  const { t } = useTranslation([
    'questionnaire',
  ]);

  const router = useRouter();

  const handleNavigationToSetup = async () => {
    await router.push(Routes.Setup.Root);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          width: '42.125rem',
          padding: '1.5rem',
          maxWidth: 'none',
        },
      }}
    >
      <DialogContent sx={{ py: 0, px: 0 }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            mb: 2,
          }}
        >
          <Typography
            variant="h6"
            component="p"
          >
            {t('questionnaire.editSetUp.dialogTitle')}
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            mb: 2,
          }}
          gap={1}
        >
          <Typography
            variant="body1"
            component="p"
          >
            {t('questionnaire.editSetUp.dialogSubtitle')}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            color="default"
            onClick={handleClose}
          >
            <Typography
              variant="subtitle1"
              component="p"
            >
              {t('questionnaire.editSetUp.closeDialog')}
            </Typography>
          </IconButton>
          <IconButton
            color="primary"
            onClick={handleNavigationToSetup}
          >
            <Typography
              variant="subtitle1"
              component="p"
            >
              {t('questionnaire.editSetUp.confirmEdit')}
            </Typography>
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
